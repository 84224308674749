@import '~react-toastify/dist/ReactToastify.min.css';

@import '~react-grid-layout/css/styles.css';

@import '~react-resizable/css/styles.css';

html,
body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

a,
a:visited {
  color: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.Toastify__toast-container {
  width: 200px;
}

.Toastify__toast {
  width: 200px;
  min-height: 20px;
}

.Toastify__toast--success {
  width: 200px;
  min-height: 20px;
  background: rgba(0, 0, 0, 0.77);
}

.Toastify__toast--error {
  width: 200px;
  min-height: 20px;
  background: rgba(180, 25, 25, 0.77);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease;
}

.fadeOut {
  animation: fadeOut 0.5s ease;
}
