@keyframes kenBurns {
  from {
    -webkit-transform-origin: var(--focus-direction);
    -moz-transform-origin: var(--focus-direction);
    -ms-transform-origin: var(--focus-direction);
    -o-transform-origin: var(--focus-direction);
    transform-origin: var(--focus-direction);
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
  }
  to {
    -webkit-transform-origin: var(--focus-direction);
    -moz-transform-origin: var(--focus-direction);
    -ms-transform-origin: var(--focus-direction);
    -o-transform-origin: var(--focus-direction);
    transform-origin: var(--focus-direction);
    transform: scale(var(--zoom-in));
    -ms-transform: scale(var(--zoom-in));
    -webkit-transform: scale(var(--zoom-in));
    -moz-transform: scale(var(--zoom-in));
    -o-transform: scale(var(--zoom-in));
  }
}

@keyframes focusZoomIn {
  0% {
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
  }
  100% {
    transform: scale(var(--zoom-in));
    -ms-transform: scale(var(--zoom-in));
    -webkit-transform: scale(var(--zoom-in));
    -moz-transform: scale(var(--zoom-in));
    -o-transform: scale(var(--zoom-in));
  }
}

@keyframes focusZoomOut {
  0% {
    transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
  100% {
    transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
  }
}
